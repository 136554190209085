<template>
  <div class="px-3">

      <!-- Select Option Input -->
        <div
          class="form-group d-flex align-items-center row w-100  mt-2"
         

        >
          <div class="col-12">
            <!-- <span class="text-dark font-weight-medium mb-3 fz-16">
              Input type:
            </span> -->
          </div>
           <div class="col-12">
            <div class="custom-select-aanswer result-select">
              <!-- <img v-if="QuestionPageType == 'singleAnswer'" class="" src="/images/singleAnswer.svg" />
              <img v-else class="" src="/images/multipleAnswer.svg" /> -->
              <img  class="" src="/images/slider.svg" />
              <!-- <img v-else src="/images/noresult.svg" /> -->
              <select
            class="form-select w-100 form-control-sm input-border-style  result-select"
            aria-label="Default select example"
            v-model="block.inputType"
          >
            <option value="range" >Slider</option>
            <option value="select">Select Item</option>
          </select>
            </div>
          </div>
        </div>

            <!-- Range Slider Inputs -->
            <div  v-if="block.inputType == 'range'">
            <div class="row my-2">
                <div class="col-4  text-start">
                    <h4 class="subheading mt-4">Min value:</h4>
                    <input
                        type="number"
                        v-model="block.content.sliderInput.minValue"
                        class="form-control-sm input-border-style "
                        style="width: 60px"
                      />
                </div>
                <div class="col-4  text-start">
                    <h4 class="subheading mt-4">Max value:</h4>
                    <input
                        type="number"
                        v-model="block.content.sliderInput.maxValue"
                        class="form-control-sm input-border-style "
                        style="width: 60px"
                      />
                </div>
                <div class="col-4  text-start">
                    <h4 class="subheading mt-4">Prefix:</h4>
                    <input
                        type="text"
                        v-model="block.content.sliderInput.prefix"
                        class="form-control-sm input-border-style "
                        style="width: 60px"
                      />
                </div>
                <div class="col-12 mt-2 d-flex align-items-center">
                    <h4 class="subheading  mr-3">Size:</h4>
                    <input
                        type="number"
                        v-model="block.style.SliderSize"
                        class="form-control-sm input-border-style "
                        style="width: 60px"
                      />
                </div>
            </div>

            <div class=" my-2">
                    <div class="form-group d-flex mb-2 align-items-center row">
        <div class="col-12">
          <span class="text-dark font-weight-medium">Selected track color:</span>
        </div>
        <div class="col-6 d-flex align-items-center pr-0">
          <input
            type="color"
            v-model="block.style.selectedColor"
            style="min-width:30px;width: 30px; height: 30px ; background:transparent"
            class="form-control-color form-control border-0"
          />
          <input type="text" class="ColorTextInput" v-model="block.style.selectedColor">
        </div>
        <div class="col-4 d-flex align-items-center px-0">
          <svg
          class="mr-2"
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
              fill="#B3AFB6"
            />
          </svg>

          <input
            type="number"
            min="0"
            max="100"
            v-model="block.style.selectedOpacity"
            class="form-control-sm input-border-style size-input text-dark"
          />
        </div>
      </div>

      <!-- Controls Colors Input -->
      <div class="form-group d-flex mb-2 align-items-center row">
        <div class="col-12">
          <span class="text-dark font-weight-medium">Controls color:</span>
        </div>
        <div class="col-6 d-flex align-items-center pr-0">
          <input
            type="color"
            v-model="block.style.controlColor"
            style="min-width:30px;width: 30px; height: 30px; background:transparent;"
            class="form-control-color form-control border-0 text-dark"
          />
          <input type="text" class="ColorTextInput" v-model="block.style.controlColor">
        </div>
        <div class="col-4 d-flex align-items-center px-0">
          <svg
          class="mr-2"
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11.7163 5.66665L6.99967 0.958313L2.28301 5.66665C0.983008 6.96665 0.333008 8.69998 0.333008 10.3666C0.333008 12.0333 0.983008 13.7916 2.28301 15.0916C3.58301 16.3916 5.29134 17.05 6.99967 17.05C8.70801 17.05 10.4163 16.3916 11.7163 15.0916C13.0163 13.7916 13.6663 12.0333 13.6663 10.3666C13.6663 8.69998 13.0163 6.96665 11.7163 5.66665ZM1.99967 10.6666C2.00801 8.99998 2.51634 7.94165 3.46634 6.99998L6.99967 3.39165L10.533 7.04165C11.483 7.97498 11.9913 8.99998 11.9997 10.6666H1.99967Z" fill="#B3AFB6"/>
          </svg>

          <input
            type="number"
            min="1"
            max="100"
            v-model="block.style.controlOpacity"
            class="form-control-sm input-border-style size-input text-dark "
          />
        </div>
      </div>
            </div>
            </div>
            
            <div v-else>
              <div class="row my-2">
                <div class="col-5  text-start">
                    <h4 class="subheading mt-4">Quantity:</h4>
                    <input
                        type="number"
                        v-model="block.content.ItemSelect.maxValue"
                        class="form-control-sm input-border-style "
                        style="width: 80px"
                      />
                </div>
                <div class="col-5  text-start">
                    <h4 class="subheading mt-4">Type:</h4>
                     <select
                      class="form-select w-100 form-control-sm input-border-style ml-1"
                      aria-label="Default select example"
                      v-model="block.content.ItemSelect.type"
                    >
                      <option value="star" selected>Star</option>
                      <option value="smile">Smile</option>
                    </select>
                </div>
                <div class="col-6 mt-2 d-flex align-items-center">
                    <h4 class="subheading  mr-3">Size:</h4>
                    <input
                        type="number"
                        v-model="block.style.ItemSize"
                        class="form-control-sm input-border-style "
                        style="width: 80px"
                      />
                </div>
                <div class="col-6 mt-2 d-flex align-items-center justify-content-around">
                   <span
                      :class="{
                        'active-svg': block.style.ItemAlign === 'left',
                      }"
                      type="button"
                      @click="block.style.ItemAlign = 'left'"
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.22223 9.2222H0.333344V10.7037H9.22223V9.2222ZM9.22223 3.29628H0.333344V4.77776H9.22223V3.29628ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                          fill="#535355"
                        />
                      </svg>
                    </span>
                    <span
                      :class="{
                        'active-svg': block.style.ItemAlign === 'center',
                      }"
                      type="button"
                      @click="block.style.ItemAlign = 'center'"
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                          fill="#B3AFB6"
                        />
                      </svg>
                    </span>
                    <span
                      :class="{
                        'active-svg': block.style.ItemAlign === 'end',
                      }"
                      type="button"
                      @click="block.style.ItemAlign = 'end'"
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                          fill="#B3AFB6"
                        />
                      </svg>
                    </span>
                </div>

                <div class=" my-2">
                    <div class="form-group d-flex mb-2 align-items-center row">
        <div class="col-12">
          <span class="text-dark font-weight-medium">Selected color:</span>
        </div>
        <div class="col-6 d-flex align-items-center pr-0">
          <input
            type="color"
            v-model="block.style.selectedColor"
            style="min-width:30px;width: 30px; height: 30px ; background:transparent"
            class="form-control-color form-control border-0"
          />
          <input type="text" class="ColorTextInput" v-model="block.style.selectedColor">
        </div>
        <div class="col-4 d-flex align-items-center px-0">
          <svg
          class="mr-2"
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
              fill="#B3AFB6"
            />
          </svg>

          <input
            type="number"
            min="0"
            max="100"
            v-model="block.style.selectedOpacity"
            class="form-control-sm input-border-style size-input text-dark"
          />
        </div>
      </div>

      <!-- Controls Colors Input -->
      <div class="form-group d-flex mb-2 align-items-center row">
        <div class="col-12">
          <span class="text-dark font-weight-medium">Controls color:</span>
        </div>
        <div class="col-6 d-flex align-items-center pr-0">
          <input
            type="color"
            v-model="block.style.controlColor"
            style="min-width:30px;width: 30px; height: 30px; background:transparent;"
            class="form-control-color form-control border-0 text-dark"
          />
          <input type="text" class="ColorTextInput" v-model="block.style.controlColor">
        </div>
        <div class="col-4 d-flex align-items-center px-0">
          <svg
          class="mr-2"
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11.7163 5.66665L6.99967 0.958313L2.28301 5.66665C0.983008 6.96665 0.333008 8.69998 0.333008 10.3666C0.333008 12.0333 0.983008 13.7916 2.28301 15.0916C3.58301 16.3916 5.29134 17.05 6.99967 17.05C8.70801 17.05 10.4163 16.3916 11.7163 15.0916C13.0163 13.7916 13.6663 12.0333 13.6663 10.3666C13.6663 8.69998 13.0163 6.96665 11.7163 5.66665ZM1.99967 10.6666C2.00801 8.99998 2.51634 7.94165 3.46634 6.99998L6.99967 3.39165L10.533 7.04165C11.483 7.97498 11.9913 8.99998 11.9997 10.6666H1.99967Z" fill="#B3AFB6"/>
          </svg>

          <input
            type="number"
            min="1"
            max="100"
            v-model="block.style.controlOpacity"
            class="form-control-sm input-border-style size-input text-dark "
          />
        </div>
      </div>
            </div>
            </div>
            </div>

                <!-- Answer Styling -->
                <div class="row my-2 text-setting">
                  <div class="col-4 d-flex align-items-center pr-1">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.08333 3.33334V5.83334H6.24999V15.8333H8.74999V5.83334H12.9167V3.33334H2.08333ZM17.9167 7.50001H10.4167V10H12.9167V15.8333H15.4167V10H17.9167V7.50001Z"
                        fill="#B3AFB6"
                      />
                    </svg>

                    <select
                      class="form-select w-100 form-control-sm input-border-style ml-1"
                      aria-label="Default select example"
                      v-model="block.style.fontFamily"
                    >
                    <option
v-for="font in getFontFamilyList"
:key="font.value"
:value="font.value"
>
{{ font.value }}
</option>


                    </select>
                  </div>
                  <div
                    class="col-8 d-flex align-items-center justify-content-center"
                  >
                    <div class="d-flex align-items-center">
                      <span class="">
                        <svg
                          width="19"
                          height="13"
                          viewBox="0 0 19 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z"
                            fill="#B3AFB6"
                          />
                          <path
                            d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z"
                            fill="#B3AFB6"
                          />
                        </svg>
                      </span>
                      <input
                        type="number"
                        min="0"
                        max="100"
                        v-model="block.style.fontSize"
                        class="form-control-sm input-border-style"
                      />
                    </div>

                    <div class="d-flex align-items-center">
                      <span
                        class="mx-1"
                        :class="{
                          'active-svg': block.style.fontWeight === 'bold',
                        }"
                        @click="
                          block.style.fontWeight =
                            block.style.fontWeight == 'normal'
                              ? 'bold'
                              : 'normal'
                        "
                      >
                        <svg
                          width="10"
                          height="12"
                          viewBox="0 0 10 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.00001 5.99165C8.80834 5.43331 9.37501 4.51665 9.37501 3.66665C9.37501 1.78331 7.91668 0.333313 6.04168 0.333313H0.833344V12H6.70001C8.44168 12 9.79168 10.5833 9.79168 8.84165C9.79168 7.57498 9.07501 6.49165 8.00001 5.99165ZM3.33334 2.41665H5.83334C6.52501 2.41665 7.08334 2.97498 7.08334 3.66665C7.08334 4.35831 6.52501 4.91665 5.83334 4.91665H3.33334V2.41665ZM6.25001 9.91665H3.33334V7.41665H6.25001C6.94168 7.41665 7.50001 7.97498 7.50001 8.66665C7.50001 9.35831 6.94168 9.91665 6.25001 9.91665Z"
                            fill="#B3AFB6"
                          />
                        </svg>
                      </span>
                      <span
                        class="mx-1"
                        :class="{
                          'active-svg': block.style.fontStyle === 'italic',
                        }"
                        @click="
                          block.style.fontStyle =
                            block.style.fontStyle == 'normal'
                              ? 'italic'
                              : 'normal'
                        "
                      >
                        <svg
                          width="10"
                          height="12"
                          viewBox="0 0 10 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.33333 0.333313V2.83331H5.175L2.325 9.49998H0V12H6.66667V9.49998H4.825L7.675 2.83331H10V0.333313H3.33333Z"
                            fill="#B3AFB6"
                          />
                        </svg>
                      </span>
                      <span
                        class="mx-1"
                        :class="{
                          'active-svg':
                            block.style.textDecoration === 'underline',
                        }"
                        @click="
                          block.style.textDecoration =
                            block.style.textDecoration == 'underline'
                              ? 'unset'
                              : 'underline'
                        "
                      >
                        <svg
                          width="12"
                          height="16"
                          viewBox="0 0 12 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.99999 12.1667C8.75832 12.1667 11 9.925 11 7.16667V0.5H8.91666V7.16667C8.91666 8.775 7.60832 10.0833 5.99999 10.0833C4.39166 10.0833 3.08332 8.775 3.08332 7.16667V0.5H0.99999V7.16667C0.99999 9.925 3.24166 12.1667 5.99999 12.1667ZM0.166656 13.8333V15.5H11.8333V13.8333H0.166656Z"
                            fill="#B3AFB6"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row my-2">
                  <div class="col-5 d-flex align-items-center pr-0 px-2">
                    <input
                      type="color"
                      v-model="block.style.color"
                      style="min-width:30px;width: 30px; height: 30px; padding: 0px"
                      class="form-control-color form-control border-0"
                    />
                    <input type="text" class="ColorTextInput" v-model="block.style.color">
                  </div>
                  <div class="col-3 d-flex align-items-center px-0">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 14 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
                        fill="#B3AFB6"
                      />
                    </svg>

                    <input
                      type="number"
                      v-model="block.style.textOpacity"
                      max="100"
                      min="0"
                      class="form-control-sm input-border-style w-100"
                    />
                  </div>
                  <!-- <div
                    class="col-4 d-flex align-items-center justify-content-around"
                  >
                    <span
                      :class="{
                        'active-svg': block.style.textAlign === 'left',
                      }"
                      type="button"
                      @click="block.style.textAlign = 'left'"
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.22223 9.2222H0.333344V10.7037H9.22223V9.2222ZM9.22223 3.29628H0.333344V4.77776H9.22223V3.29628ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                          fill="#535355"
                        />
                      </svg>
                    </span>
                    <span
                      :class="{
                        'active-svg': block.style.textAlign === 'center',
                      }"
                      type="button"
                      @click="block.style.textAlign = 'center'"
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                          fill="#B3AFB6"
                        />
                      </svg>
                    </span>
                    <span
                      :class="{
                        'active-svg': block.style.textAlign === 'end',
                      }"
                      type="button"
                      @click="block.style.textAlign = 'end'"
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                          fill="#B3AFB6"
                        />
                      </svg>
                    </span>
                  </div> -->
                </div>

                <!-- Margin -->
                <h4 class="subheading mt-4">Margins:</h4>
                <div class="row m-0">
                  <div class="row align-items-center">
                    <div class="col-4 d-flex">
                      <div class="row align-items-center">
                        <div class="col-4">
                          <svg
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                              fill="#B3AFB6"
                            />
                          </svg>
                        </div>
                        <div class="col-8">
                          <input
                            type="number"
                            v-model="block.style.marginTop"
                            class="form-control-sm w-75 px-2  input-border-style"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-4 d-flex">
                      <div class="row align-items-center">
                        <div class="col-4">
                          <svg
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                              fill="#B3AFB6"
                            />
                          </svg>
                        </div>
                        <div class="col-8">
                          <input
                            type="number"
                            v-model="block.style.marginBottom"
                            class="form-control-sm w-75 px-2  input-border-style"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row w-100">
                    <div class="col-8 text-center">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.24999 9.99999C3.24999 8.57499 4.40832 7.41666 5.83332 7.41666H9.16666V5.83333H5.83332C3.53332 5.83333 1.66666 7.69999 1.66666 9.99999C1.66666 12.3 3.53332 14.1667 5.83332 14.1667H9.16666V12.5833H5.83332C4.40832 12.5833 3.24999 11.425 3.24999 9.99999ZM6.66666 10.8333H13.3333V9.16666H6.66666V10.8333ZM14.1667 5.83333H10.8333V7.41666H14.1667C15.5917 7.41666 16.75 8.57499 16.75 9.99999C16.75 11.425 15.5917 12.5833 14.1667 12.5833H10.8333V14.1667H14.1667C16.4667 14.1667 18.3333 12.3 18.3333 9.99999C18.3333 7.69999 16.4667 5.83333 14.1667 5.83333Z"
                          fill="#B3AFB6"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-4 d-flex">
                      <div class="row align-items-center">
                        <div class="col-4">
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                              fill="#B3AFB6"
                            />
                          </svg>
                        </div>
                        <div class="col-8">
                          <input
                            type="number"
                            v-model="block.style.marginLeft"
                            class="form-control-sm w-75 px-2  input-border-style"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-4 d-flex">
                      <div class="row align-items-center">
                        <div class="col-4">
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                              fill="#B3AFB6"
                            />
                          </svg>
                        </div>
                        <div class="col-8">
                          <input
                            type="number"
                            v-model="block.style.marginRight"
                            class="form-control-sm w-75 px-2  input-border-style"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>
<script>
import fontFamilyList from "../../../staticPages/fontFamilyList";
export default {
  props: {
    block: Object,
  },
  data() {
    return {
    }; 
  },
  computed:{
    getFontFamilyList() {
return fontFamilyList;
},
textBlockFont(){
      return this.block.style.fontFamily
    },
  },
  watch:{
    textBlockFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    }
  }
};
</script>
<style  scoped>
.ql-container.ql-snow {
  background: white;
}
.form-control-sn {
  border: 1px solid;
  width: 100%;
  border-radius: 3px;
  /* line-height: 1rem; */
  height: 26px;
  font-size: 14px;
}
.subheading {
  color: #18191c;
  font-size: 14px;
  font-weight: 700;
  
}
.subheading::first-letter {
  text-transform: uppercase;
  
}
.active-svg {
  padding: 3px;
  background: #b3afb6;
  border-radius: 3px;
  
}
.active-svg path{
   fill: #323232;
  
}



.custom-select-aanswer.result-select
{
  border:none!important;
  width: 100%;
    padding-left: 0px;
}
.result-select
{
  padding-left:35px;
  background: #F4F3F5;
  height: inherit;
  border: none!important;
}
.custom-select-aanswer {
  display: flex;
  border: 1px solid #000000;
  border-radius: 6px;
  height: 36px;
  /* background:#ededee; */
  align-items: center;
}
.custom-select-aanswer img {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 24px;
}


</style>
